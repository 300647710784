<script>
const api = "https://api.yhl168.top"; // 生产
// const api = 'http://8.130.15.151:3000';  // 本地
const file = "https://upload.yhl168.top/upload"

export default {
  api,
  file
};
</script>
