import axios from 'axios';
import vue from 'vue';
import _ from '../api/api.vue';
// import VueRouter from 'vue-router';
import Router from 'vue-router';
vue.use(Router);

// 前端路由
const route = new Router({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: resolve => require(['../components/login.vue'], resolve),
            // component: resolve => require(['../components/wx_login.vue'], resolve),
            meta: {
                title: '前川易货',
                requiresAuth: true
            }
        },
        {
            path: '/home',
            name: 'home',
            component: resolve => require(['../components/home.vue'], resolve),
            children: [
                {
                    path: '/homepage', // 首页
                    name: 'homePage',
                    component: resolve => require(['../components/childPage/homePage.vue'], resolve)
                },
                {
                    path: '/product/list', // 商品列表
                    name: 'ProductList',
                    component: resolve => require(['../components/childPage/administration/productList.vue'], resolve)
                },
                {
                    path: '/entering',  // 商品入库
                    name: 'entering',
                    component: resolve => require(['../components/childPage/administration/entering.vue'], resolve)
                },
                {
                    path: '/protout',  // 商品出库
                    name: 'pro-out',
                    component: resolve => require(['../components/childPage/administration/pro-out.vue'], resolve)
                },
                {
                    path: '/compile/:id', // 编辑商品
                    name: 'compile',
                    component: resolve => require(['../components/childPage/administration/compile.vue'], resolve)
                },
                {
                    path: '/entering', // 商品录入
                    name: 'entering',
                    component: resolve => require(['../components/childPage/administration/entering.vue'], resolve)
                },
                {
                    path: '/contract/list', // 合同列表
                    name: 'contract-list',
                    component: resolve => require(['../components/childPage/contract/contract-list.vue'], resolve)
                },
                {
                    path: '/fix/contract/:id',
                    name: 'fix-contract',
                    component: resolve => require(['../components/childPage/contract/fix-info.vue'], resolve)
                },
                {
                    path: '/create/contract', // 创建合同
                    name: 'entering',
                    component: resolve => require(['../components/childPage/contract/creat-contract.vue'], resolve)
                },
                {
                    path: '/store/list', // 店铺列表
                    name: 'storeList',
                    component: resolve => require(['../components/childPage/store/list.vue'], resolve)
                },
                {
                    path: '/store/extension', // 店铺授信
                    name: 'storeExtension',
                    component: resolve => require(['../components/childPage/store/extension.vue'], resolve)
                },
                {
                    path: '/store/fix/extension/:id', // 店铺关系编辑
                    name: 'storeFixExtension',
                    component: resolve => require(['../components/childPage/store/fix-extension.vue'], resolve)
                },
                {
                    path: '/store/extension/record', // 店铺授信额度记录
                    name: 'storeRecord',
                    component: resolve => require(['../components/childPage/store/extension-record.vue'], resolve)
                },
                {
                    path: '/fix/store/:id', // 修改店铺信息
                    name: 'fixStore',
                    component: resolve => require(['../components/childPage/store/fix-store.vue'], resolve)
                },
                {
                    path: '/store/create/user', // 店铺账号管理
                    name: 'cratedUser',
                    component: resolve => require(['../components/childPage/store/accountSetting.vue'], resolve)
                },
                {
                    path: '/store/credit/extension', // 新建授信店铺
                    name: 'credit-extension',
                    component: resolve => require(['../components/childPage/store/credit-extension.vue'], resolve)
                },
                {
                    path: '/order/list',  // 订单列表
                    name: 'order-list',
                    component: resolve => require(['../components/childPage/order/order-list.vue'], resolve)
                },
                {
                    path: '/review/order/:id',
                    name: 'review-order',
                    component: resolve => require(['../components/childPage/order/reviewOrder.vue'], resolve)
                },
                {
                    path: '/cashier/desk', // 收银台
                    name: 'cashier-desk',
                    component: resolve => require(['../components/childPage/shop/cashier-desk.vue'], resolve)
                },
                {
                    path: '/member/list',  // 会员列表
                    name: 'member-list',
                    component: resolve => require(['../components/childPage/member-management.vue/member-list.vue'], resolve)
                },
                {
                    path: '/member/create/VIP', // 创建会员
                    name: 'create-VIP',
                    component: resolve => require(['../components/childPage/member-management.vue/create-VIP.vue'], resolve)
                },
                {
                    path: '/fix/vip/:id', // 修改会员信息
                    name: 'fix-vip',
                    component: resolve => require(['../components/childPage/member-management.vue/fix-vip.vue'], resolve)
                },
                {
                    path: '/admin/list', // 管理员列表
                    name: 'admin-list',
                    component: resolve => require(['../components/childPage/admin/admin-list.vue'], resolve)
                },
                {
                    path: '/create/admin', // 创建管理员
                    name: 'create-admin',
                    component: resolve => require(['../components/childPage/admin/create-admin.vue'], resolve)
                },
                {
                    path: '/fix/admin/:id', // 修改管理员
                    name: 'fix-admin',
                    component: resolve => require(['../components/childPage/admin/fix-admin.vue'], resolve)
                },
                {
                    path: '/wx/set', // 小程序配置
                    name: 'wx-set',
                    component: resolve => require(['../components/childPage/wxSet/set.vue'], resolve)
                },
                {
                    path: '/upload/file', // uploadFile删除
                    name: 'uploadFile',
                    component: resolve => require(['../components/childPage/administration/uploadFile.vue'], resolve)
                },
                {
                    path: '/logs',
                    name: 'logs',
                    component: resolve => require(['../components/childPage/logs.vue'],resolve)
                },
                {
                    path: '/created/store',
                    name: 'createdStore',
                    component: resolve => require(['../components/childPage/store/created-store.vue'],resolve)
                }
            ]
        },
        {
            path: '*',
            name: 'login',
            component: resolve => require(['../components/login.vue'], resolve),
        },
    ],

})
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
route.afterEach((to) => {
    console.log('---to:',api,to);
    const token = localStorage.getItem('access_token')
    if (to.name == 'ProductList' || to.name == 'home' || to.name == 'homePage' || to.name == 'ProductList' || to.name == 'pro-out' || to.name == 'entering' || to.name == 'storeList' || to.name == 'storeExtension' || to.name == 'storeRecord' || to.name == 'create-VIP' || to.name == 'compile' || to.name == 'contract-list' || to.name == 'order-list' || to.name == 'cashier-desk' || to.name == 'member-list' || to.name == 'fix-contract' || to.name == 'fix-vip-info' || to.name == 'fix-vip' || to.name == 'review-order' || to.name == 'fix-admin' || to.name == 'wx-set' || to.name == 'logs' || to.name == 'cratedUser' || to.name == 'fixStore' || to.name == 'credit-extension' || to.name == 'storeFixExtension' || to.name == 'admin-list' || to.name == 'create-admin') {
        axios.post('/verify', {
            token
        }).then(data => {
            if (data.data.statusCode == 400) {
                this.$message.error("登录已失效")
                console.log('TOKEN INVALIDATION');
                this.$message({
                    type: 'error',
                    message: '登陆失效，请重新登陆！'
                })
                route.push('/login')
            }
        })
    }
})
//  路由判断登录 根据路由配置文件的参数
route.beforeEach((to, from, next) => {
    //  to,将要访问的路径
    // from  从哪个路径跳转过来的
    // next 是一个函数,代表放行
     let token = sessionStorage.getItem('loginTime')
      if(to.path==='/login'){
       return next()
      }else{
        if(!token){
         return next('/login')
        }else{
          next()
        }
      }
   })
export default route