import Vue from 'vue';
import VueRouter from 'vue-router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/sass/style.min.css'
import App from './App.vue'
import router from './route/route'
// vue-barcode 条形码包
import VueBarcode from "vue-barcode";
import VueCookies from 'vue-cookies';
import VueSession from 'vue-session';
import numeral from 'numeral';
const echarts = require('echarts');
Vue.prototype.$echarts = echarts
Vue.filter("formatNumber", function (value) {
  return numeral(value).format('¥0,0.00');
});
Vue.use(VueSession)
import _ from 'lodash';
Vue.prototype._ = _;
Vue.use(VueCookies);

// 全局api
import global from '../src/api/api.vue'
Vue.prototype.GLOBAL=global;
 

Vue.config.productionTip = false
Vue.component('barcode', VueBarcode);
Vue.use(ElementUI);
Vue.use(VueRouter)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
