<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  watch: {
    $route: function () {
      window.scrollTo(0,0)
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    }
  },
  metaInfo: {
    title: "前川易货后台管理系统",
  },
}
</script>
<style scoped>
#app {
  min-width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0 0;
  padding: 0 0;
}
</style>
